import * as React from 'react';
import SEO from '../components/SEO/SEO';
import Header from '../components/Header/Header';
import * as styles from './PageLayout.module.scss';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as SvgSprite from '../assets/SvgSprite';
import SectionMoreInfo from '../components/SectionMoreInfo/SectionMoreInfo';
import CustomCarousel from '../components/CustomCarousel/CustomCarousel';
import SectionMailingList from '../components/SectionMailingList/SectionMailingList';
import SectionFriends from '../components/SectionFriends/SectionFriends';

export function PageLayout(props) {
  const { pageContext } = props;

  const photosArray = pageContext.data.Photo_Gallery?.localFiles;

  return (
    <>
      <SEO
        fullName={pageContext.data.Full_Name}
        gtm={pageContext.data.GTM}
        metaURL={pageContext.data.metaURL}
        description={pageContext.data.Description}
        OGImage={pageContext.data?.OGimage?.localFiles?.[0]?.childrenImageSharp?.[0]?.gatsbyImageData?.images?.fallback?.src}
        title={pageContext.data.Title}
      />
      <Header pageContext={pageContext} />
      <main className={styles.mainContent}>
        <section className={styles.sectionBtnExclusive}>
          {!pageContext.data.Main_Button_Link || pageContext.data.Main_Button_Link?.includes('onlyfans') ? (
            <Link
              to={`/${pageContext.data.Name}/out`}
              className={styles.btnExclusiveContent}
              style={{ backgroundColor: pageContext.data.Main_Button_Hex_Color }}
            >
              {(pageContext.data['Main_Button_Icon'] && pageContext.data['Main_Button_Icon']?.localFiles.length) ? (
                <div className={styles.btnIcon}>
                  <GatsbyImage image={getImage(pageContext.data['Main_Button_Icon'].localFiles[0].childImageSharp)} alt='Button Icon' />
                </div>
              ) : (
                <SvgSprite.IconOnlyfans />
              )}
              {pageContext.data.Main_Button_Text || "See my exclusive content"}
            </Link>
          ) : (
            <a
              href={pageContext.data.Main_Button_Link}
              className={styles.btnExclusiveContent}
              style={{ backgroundColor: pageContext.data.Main_Button_Hex_Color }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {(pageContext.data['Main_Button_Icon'] && pageContext.data['Main_Button_Icon']?.localFiles.length) ? (
                <div className={styles.btnIcon}>
                  <GatsbyImage image={getImage(pageContext.data['Main_Button_Icon'].localFiles[0].childImageSharp)} alt='Button Icon' />
                </div>
              ) : (
                <SvgSprite.IconOnlyfans />
              )}
              {pageContext.data.Main_Button_Text || "See my exclusive content"}
            </a>
          )}
          {pageContext.data['_2nd_Button'] && (
            <a
              href={pageContext.data['_2nd_Button_Link']}
              style={{ backgroundColor: pageContext.data['_2nd_Button_Hex_Color'] }}
              className={styles.btnExclusiveContent}
              target="_blank" rel="noreferrer"
            >
              {pageContext.data['_2nd_Button_Icon'] && (
                <div className={styles.btnIcon}>
                  <GatsbyImage image={getImage(pageContext.data['_2nd_Button_Icon'].localFiles[0].childImageSharp)} alt='Button Icon' />
                </div>
              )}
              {pageContext.data['_2nd_Button_Text']}
            </a>
          )}
        </section>

        <SectionMoreInfo pageContext={pageContext} />

        {pageContext.data.Photo_Gallery && (
          <section className={styles.sectionGallery}>
            <CustomCarousel
              data={photosArray}
              feedCarousel={true}
              altText={pageContext.data.Full_Name}
            />
          </section>
        )}
        {pageContext.data.Mailing_List_Code && (
          <section id="sectionForm" className={styles.sectionForm}>
            <SectionMailingList
              mailingListCode={pageContext.data.Mailing_List_Code}
            />
          </section>
        )}

        {pageContext.data.Status &&
          !!pageContext.data.Status.length &&
          pageContext.data.Status.includes('NoFriends') ? null : (
          <SectionFriends pageContext={pageContext} />
        )}

        {!pageContext.data.Hide_Footer_Logo && (
          <div className={styles.footer}>
            <div className={styles.bottomLogo}>
              <a href="https://ash.agency">
                <SvgSprite.Logo />
              </a>
            </div>

            <div className={styles.privacyPolicy}>
              <a href="/privacy">
                Privacy Policy
              </a>
            </div>
          </div>
        )}
      </main>
      {/* {pageContext.data.GTM && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${pageContext.data.GTM}');`,
          }}
        />
      )} */}
    </>
  );
};